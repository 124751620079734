<div class="modal-wrapper">
  <h2>{{data.title}}</h2>
  <hr>
  <div class="modal-content">
    <div [innerHtml]="data.description"></div>
  </div>
  <div>
    <rea-button [ngClass]="{'primary': data.okLabel.color=== 'PRIMARY', 'warn': data.okLabel.color === 'WARN'}"
                (clickHandler)="submit()">{{data.okLabel.content}}</rea-button>
    <rea-button [ngClass]="{'neutral': data.okLabel.color=== 'NEUTRAL'}" class="cancel-button"
                (clickHandler)="cancel()">{{data.cancelLabel.content}}</rea-button>
  </div>
</div>
